import React, { ReactElement, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useMount } from 'react-use';
import { Action, Column, Field, ISelectOption, UIType } from '@zeroedin-tech/zi-common-ui/lib/';
import SchnurTable from '@zeroedin-tech/zi-common-ui/lib/components/SchnurTable/SchnurTable';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalVariant } from '@patternfly/react-core';
import SchnurForm from '@zeroedin-tech/zi-common-ui/lib/components/SchnurForm/SchnurForm';
import { DatabaseServer, TDatabaseServer } from '../api/databaseServer/DatabaseServer';
import { SubheaderContext } from '../layout/Layout';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import FilterTableLayout from '../layout/FilterTableLayout';
import { TZone, Zone } from '../api/zone/Zone';
import { DatabaseInstance } from '../api/databaseInstance/DatabaseInstance';

export default function Zones(): ReactElement {
	const { addToast } = useToast();
	const [setSubheader]: SubheaderContext = useOutletContext();
	const [tableData, setTableData] = useState<TZone[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [activeZone, setActiveZone] = useState<TZone>(Zone.Default() as TZone);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
	const [databaseServers, setDatabaseServers] = useState<TDatabaseServer[]>([]);

	const selectedColumns: Column<TZone>[] = [
		{
			title: 'Name',
			columnName: 'name',
		},
		{
			title: 'Prefix',
			columnName: 'prefix',
		},
		{
			title: 'App Database',
			columnName: 'appDatabaseInstance',
			customAccessor: (item) => {
				if (typeof item.appDatabaseInstance === 'object') {
					return item.appDatabaseInstance.dbname;
				} else {
					return String(item);
				}
			},
		},
		{
			title: 'Data Database',
			columnName: 'appDatabaseInstance',
			customAccessor: (item) => {
				if (typeof item.appDatabaseInstance === 'object') {
					return item.appDatabaseInstance.dbname;
				} else {
					return String(item);
				}
			},
		},
		{
			title: 'Is Builder Zone',
			columnName: 'is_builder',
			customAccessor: (item) => {
				return String(item.is_builder ? 'Yes' : 'No');
			},
		},
	];

	const actions: Action<TZone>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveZone(item);
				setIsModalOpen(true);
			},
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);

				Zone.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setTableData((prev) => prev.filter((entity) => entity.id !== item.id));
						addToast('System User deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast('Failed to delete System User.', AlertVariant.danger);
					});
			},
		},
	];

	const formProperties: Field<TZone>[] = [
		{
			title: 'Nickname',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
			required: true,
		},
		{
			title: 'Prefix',
			columnName: 'prefix',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'App Server',
			columnName: 'appDatabaseInstance',
			uiSchema: {
				type: UIType.SELECT,
				options: databaseServers.map((databaseServer) => {
					return {
						value: databaseServer.name,
						key: databaseServer.id,
						description: databaseServer.host,
					};
				}),
				onSelect: (option: ISelectOption) => {
					return option.key;
				},
				initialSelection:
					databaseServers.find((databaseServer) => {
						const appDatabaseInstance =
							activeZone.appDatabaseInstance as DatabaseInstance;

						const databaseServerObject =
							appDatabaseInstance.databaseServer as DatabaseServer;

						if (!appDatabaseInstance || !databaseServerObject) {
							return false;
						}

						return databaseServer?.id === databaseServerObject.id;
					})?.name ?? undefined,
			},
			required: true,
		},
		{
			title: 'Data Server',
			columnName: 'dataDatabaseInstance',
			uiSchema: {
				type: UIType.SELECT,
				options: databaseServers.map((databaseServer) => {
					return {
						value: databaseServer.name,
						key: databaseServer.id,
						description: databaseServer.host,
					};
				}),
				onSelect: (option: ISelectOption) => {
					return option.key;
				},
				initialSelection:
					databaseServers.find((databaseServer) => {
						const dataDatabaseInstance =
							activeZone.dataDatabaseInstance as DatabaseInstance;

						const databaseServerObject =
							dataDatabaseInstance.databaseServer as DatabaseServer;

						if (!dataDatabaseInstance || !databaseServerObject) {
							return false;
						}

						return databaseServer?.id === databaseServerObject.id;
					})?.name ?? undefined,
			},
			required: true,
		},
		{
			title: 'Is Builder Zone',
			columnName: 'is_builder',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
	];

	useMount(() => {
		setSubheader(
			<PageTitleSubheader
				pageTitle="Zones"
				pageDescription="Manage your Zones."
			/>
		);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		Zone.GetAll([
			'appDatabaseInstance',
			'appDatabaseInstance.databaseServer',
			'dataDatabaseInstance',
			'dataDatabaseInstance.databaseServer',
		])
			.then((tableData) => {
				setTableData(tableData);
				setTableLoading(false);
			})
			.catch(() => {
				addToast('Failed to load Zones.', AlertVariant.danger);
			});

		DatabaseServer.GetAll()
			.then((databaseServers) => {
				setDatabaseServers(databaseServers);
				setIsFormLoading(false);
			})
			.catch(() => {
				addToast('Failed to load Database Servers.', AlertVariant.danger);
			});
	});

	const addButton = (
		<Button
			data-testid={'add-button'}
			variant={'primary'}
			onClick={() => {
				setActiveZone(Zone.Default() as TZone);
				setIsModalOpen(true);
			}}
		>
			Provision new Zone
		</Button>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: TZone) => {
		const index = tableData.findIndex((row) => row.id === value.id);

		if (index >= 0) {
			setTableData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setTableData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const entityTable = (
		<SchnurTable<TZone>
			ariaLabel={'Zones'}
			columns={selectedColumns}
			data={tableData}
			caption="Zones"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Zone Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TZone>
					title={'Zone Management'}
					fields={formProperties}
					initialSubject={activeZone}
					isLoading={isFormLoading}
					onSubmit={(entity) => {
						setIsFormLoading(true);

						if ('id' in entity) {
							Zone.Update(entity, ['appDatabaseInstance', 'dataDatabaseInstance'])
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									setActiveZone(entity);
									addToast(
										'An error occurred while trying to update the zone. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							Zone.Provision(entity, ['appDatabaseInstance', 'dataDatabaseInstance'])
								.then((newEntity) => {
									handleSuccess(newEntity);
								})
								.catch((_error) => {
									addToast(
										'An error occurred while trying to provision the zone. Please try again later.',
										AlertVariant.danger
									);
									setIsModalOpen(false);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={entityTable}
					layoutActions={addButton}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
