import axiosConfig from '../axiosConfig';
import { TDatabaseInstance } from '../databaseInstance/DatabaseInstance';

export type TZone = {
	appDatabaseInstance: TDatabaseInstance | number;
	dataDatabaseInstance: TDatabaseInstance | number;
	appUsers: unknown[];
	description: string | null;
	id: number;
	name: string;
	prefix: string;
	is_builder: boolean;
};

export type TNewZone = Omit<TZone, 'id' | 'appUsers'>;
type keyOfTZone = keyof TZone | `${keyof TZone}.${keyof TDatabaseInstance}`;
export const zoneURL = 'admin/zone';

export class Zone implements TZone {
	appDatabaseInstance: number | TDatabaseInstance;
	dataDatabaseInstance: number | TDatabaseInstance;
	appUsers: unknown[];
	description: string | null;
	id: number;
	name: string;
	prefix: string;
	is_builder: boolean;

	constructor(entity: TZone) {
		this.appDatabaseInstance = entity.appDatabaseInstance;
		this.dataDatabaseInstance = entity.dataDatabaseInstance;
		this.appUsers = entity.appUsers;
		this.description = entity.description;
		this.id = entity.id;
		this.name = entity.name;
		this.prefix = entity.prefix;
		this.is_builder = entity.is_builder;
	}
	public static Default(): TNewZone {
		return {
			description: null,
			name: '',
			prefix: '',
			appDatabaseInstance: 0,
			dataDatabaseInstance: 0,
			is_builder: false,
		};
	}

	public static GetAll(expand: keyOfTZone[] = []): Promise<TZone[]> {
		return axiosConfig.instance
			.get(zoneURL + String('s'), { params: { expand: expand } })
			.then((res) => {
				return (res.data as TZone[]).map((entity: TZone) => {
					return entity;
				});
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`${zoneURL}/${String(id)}`).then(() => {
			return true;
		});
	}

	public static Provision(zoneInfo: TNewZone, expand: (keyof TZone)[] = []): Promise<TZone> {
		return axiosConfig.instance
			.post(
				'/admin/zone-provision',
				{
					app_database_server_id: zoneInfo.appDatabaseInstance,
					data_database_server_id: zoneInfo.dataDatabaseInstance,
					name: zoneInfo.name,
					prefix: zoneInfo.prefix,
					description: zoneInfo.description,
				},
				{ params: { expand: expand } }
			)
			.then((res) => res.data as TZone);
	}

	public static LoginTo(zoneId: number): Promise<void> {
		return axiosConfig.instance
			.post('/admin/zone-login', {
				id: zoneId,
			})
			.then();
	}

	public static Update(entity: TZone, expand: (keyof TZone)[] = []): Promise<TZone> {
		return axiosConfig.instance
			.patch(zoneURL, entity, { params: { expand: expand } })
			.then((res) => {
				return res.data as TZone;
			});
	}
}
